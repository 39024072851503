import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from '../components/Authorization';
import ScrollToTop from './ScrollToTop';
import { Account } from '../pages/Account/Account/Account';
import { Login } from '../pages/Authentication/Login/Login';
import { SignUp } from '../pages/Authentication/SignUp/SignUp';
import { SignUpCompany } from '../pages/Authentication/SignUpCompany/SignUpCompany';
import { CampaignDetails } from '../pages/CampaignDetails/CampaignDetails';
import { CampaignRegistration } from '../pages/CampaignRegistration/CampaignRegistration';
import { Community } from '../pages/Community/Community';
import { Followers } from '../pages/Following/Account/Followers';
import { Followings } from '../pages/Following/Account/Followings';
import { UserFollowers } from '../pages/Following/UserFollowers';
import { Home } from '../pages/Home/Home';
import { MyAccount } from '../pages/Account/MyAccount/MyAccount';
import { Search } from '../pages/Search/Search';
import { Settings } from '../pages/Account/MyAccount/Settings/Settings';
import { UserFollowings } from '../pages/Following/UserFollowings';
import { MyBrandAccount } from '../pages/Account/BrandAccount/MyBrandAccount/MyBrandAccount';
import { BrandAccount } from '../pages/Account/BrandAccount/BrandAccount/BrandAccount';
import { Payment } from '../pages/Payment/Payment';
import { StripeSuccess } from '../pages/Stripe/Success';
import { StripeError } from '../pages/Stripe/Error';
import { Terms } from '../pages/Guide/Terms';
import { Policy } from '../pages/Guide/Policy';
import { About } from '../pages/About/About';
import { CampaignBuyers } from '../pages/CampaignDetails/pages/buyers/CampaignBuyers';
import { MyWishlist } from '../pages/MyWishlist';
import { MyOrders } from '../pages/MyOrders';
import { MyCommissions } from '../pages/MyCommissions/MyCommissions';
import { MyMessages } from '../pages/Messages/MyMessages';
import { MyNotifications } from '../pages/MyNotifications';
import { CampaignList } from '../pages/Company/CampaignList';
import Chat from '../pages/Messages/Chat';
import { EarnCommissionDescription } from '../pages/EarnCommissionDescription/EarnCommissionDescription';

export const Navigation = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      <PublicRoute exact path="/" component={Home} />
      <PublicRoute restricted exact path="/login" component={Login} />
      <PublicRoute restricted exact path="/signup" component={SignUp} />
      <PublicRoute
        exact
        path="/campaign/:campaignId/buyers"
        component={CampaignBuyers}
      />
      <PublicRoute
        exact
        path="/campaign/:campaignId/:userId?"
        component={CampaignDetails}
      />
      <PrivateRoute exact path="/signup-company" component={SignUpCompany} />
      <PrivateRoute exact path="/brand/:id" component={BrandAccount} />
      <PrivateRoute
        exact
        path="/campaign-registration"
        component={CampaignRegistration}
      />
      <PrivateRoute
        exact
        path="/edit/:campaignId"
        component={CampaignRegistration}
      />
      <PrivateRoute exact path="/search" component={Search} />
      <PrivateRoute exact path="/community" component={Community} />
      <PrivateRoute exact path="/users/:username" component={Account} />
      <PrivateRoute
        exact
        path="/users/:username/followers"
        component={UserFollowers}
      />
      <PrivateRoute
        exact
        path="/users/:username/following"
        component={UserFollowings}
      />
      <PrivateRoute exact path="/account" component={MyAccount} />
      <PrivateRoute exact path="/account/brand" component={MyBrandAccount} />
      <PrivateRoute exact path="/account/wishlist" component={MyWishlist} />
      <PrivateRoute exact path="/account/orders" component={MyOrders} />
      <PrivateRoute
        exact
        path="/account/commissions"
        component={MyCommissions}
      />
      <PrivateRoute exact path="/account/messages" component={MyMessages} />
      <PrivateRoute exact path="/account/messages/:username" component={Chat} />
      <PrivateRoute
        exact
        path="/account/notifications"
        component={MyNotifications}
      />
      <PrivateRoute exact path="/account/followers" component={Followers} />
      <PrivateRoute exact path="/account/settings" component={Settings} />
      <PrivateRoute exact path="/account/following" component={Followings} />
      <PrivateRoute
        exact
        path="/account/company/campaigns"
        component={CampaignList}
      />
      <PrivateRoute exact path="/payment/:id" component={Payment} />
      <PublicRoute exact path="/stripe/success" component={StripeSuccess} />
      <PublicRoute exact path="/stripe/error" component={StripeError} />
      <PublicRoute exact path="/terms" component={Terms} />
      <PublicRoute exact path="/policy" component={Policy} />
      <PublicRoute exact path="/about" component={About} />
      <PublicRoute
        exact
        path="/earn-commission-description"
        component={EarnCommissionDescription}
      />
    </Switch>
  </Router>
);
